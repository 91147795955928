import React from 'react'
import ActivitySection from '../components/CommonInterface/LoginSignup/Activity/ActivitySection'

function LoginSignup() {
  return (
    <>
        <ActivitySection/>
    </>
  )
}

export default LoginSignup
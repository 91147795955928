import React from 'react'
import Forgot from '../components/CommonInterface/ForgotPassword/Forgot'

function ForgotPassword() {
  return (
    <>
        <Forgot/>
    </>
  )
}

export default ForgotPassword
import React from 'react'
import UpdatePass from '../components/CommonInterface/UpdatePassword/UpdatePass'

function UpdatePassword() {
  return (
    <>
        <UpdatePass/>
    </>
  )
}

export default UpdatePassword